<template>
  <div class="max-w-lg w-full lg:max-w-xs">
    <label for="search" class="sr-only">Pesquisar</label>
    <div class="relative text-gray-400 focus-within:text-gray-600">
      <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
        </svg>
      </div>
      <input v-model="search" @focus="focus = true" @blur="focus = false" id="search" class="block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-600 focus:ring-white focus:border-white sm:text-sm" placeholder="Pesquisar" type="text" name="search" autocomplete="off">
      <transition
        enter-active-class="transition ease-in duration-200 transform"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in duration-200 transform"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ul v-if="search.length && suggestions.length && focus" class="group-focus:bg-red-500 absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
          <li @click="redirect(item), search = ''" v-for="(item, index) in suggestions" :key="index" class="text-gray-900 hover:text-white hover:bg-primary cursor-default select-none relative py-2 pl-3 pr-9" id="listbox-option-0" role="option">
            <span class="font-normal block truncate">
              <span v-if="item.Type === 'categorie'" class="font-medium mr-1.5">Categoria:</span>
              {{ item.Value }}
            </span>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
import { listingsColl } from '@/firebase'

export default {
  data() {
    return {
      items: [],
      search: '',
      focus: false,
    }
  },

  computed: {
    suggestions: function() {
      return this.items.filter((str) => { return str.Value.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 })
    }
  },

  mounted() { this.getListing() },
  
  methods: {
    async getListing() {
      listingsColl.doc('Products').get()
      .then((result) => {
        result.data().available.filter((thing, index, self) => self.findIndex(t => t.categorie === thing.categorie) === index).map(item => { this.items.push({ Type: 'categorie', Value: item.categorie }) })
        result.data().available.map(item => { this.items.push({ Type: 'product', Value: item.title, Id: item.id }) })
      })
    },

    redirect(item) {
      if (item.Id && item.Id !== this.$router.currentRoute.params.id) { this.$router.push(`/produto/${item.Id}`) }
      else if (item.Type === 'categorie') { this.$router.push(`/produtos/${item.Value}`) }
    }
  }
}
</script>